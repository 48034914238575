<script lang="ts" setup>
withDefaults(defineProps<{
  title?: string
}>(), {
  title: undefined
});
</script>

<template>
  <v-container class="pb-0">
    <v-row class="mt-2">
      <v-col class="pb-2">
        <h3 class="text-h3 h-one-line">
          {{ title }}
        </h3>
      </v-col>
    </v-row>
  </v-container>
</template>
